export default class UrlHandler{

    constructor(){

        this._currentUrls = {

            "helmet":"",
            "chestplate":"",
            "leggings": "",
            "boots": ""
        }
    }   

    updateGenUrlObj(part, url){

         if(!Object.keys(this._currentUrls).includes(part))
            console.log("part does not exist, part:", part);

        this._currentUrls[part] = url;
    }

    genFullUrlFromObjs(){

        return `${this._currentUrls["helmet"]}&${this._currentUrls["chestplate"]}&${this._currentUrls["leggings"]}&${this._currentUrls["boots"]}`;
    }

    updateUrl(){

        this._setUrl(this.genFullUrlFromObjs());
    }

    _parseUrl(url){

        const urlParts = url.split("&");

        for(const part of urlParts){

            const partSplit = part.split("=");
            this.updateGenUrlObj(partSplit[0], partSplit[1]);
        }
    }

    _readUrl(){

        const url = window.location.search;
        this.parseUrl(url);
    }

    _setUrl(url){

        window.history.pushState({}, "", `?${url}`);
    }

    removeUrl(){

        window.history.pushState({}, "", "/");
    }
}