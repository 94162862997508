import TrimInfoLoader from "./trim_info_loader";
import UrlHandler from "./UrlHandler";

export default class ExtraTool{

    constructor(){
        /** @type {UrlHandler} */
        this.urlHandler = new UrlHandler();
        this.trimInfoLoader = new TrimInfoLoader();
    }
}