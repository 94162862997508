import Object3D from "./object3d";
import Paths from "./paths";
import ArmorPiece from "./armor_piece";
import glint from "./../textures/enchanted_glint_entity.png";

export default class ArmorPiecesHandler {

    constructor(scene, extraTool) {

        this.scene = scene;

        /** 
         * @type {Object.<string, ArmorPiece>}
        */
        this.pieces = {};

        this.createArmorObjects(scene, extraTool);
    }

    createArmorObjects(scene, extraTool) {

        const armorModelUrls = {
            "helmet": new URL('../objects/helmet.obj', import.meta.url),
            "chestplate": new URL('../objects/chestplate.obj', import.meta.url),
            "elytra": new URL('../objects/elytra.obj', import.meta.url),
            "leggings": new URL('../objects/leggings.obj', import.meta.url),
            "boots": new URL('../objects/boots.obj', import.meta.url),
        }

        const paths = new Paths();

        for (const pieceKey of paths.pieceTypes) {

            for (const material of paths.pieceMaterials) {

                const url = armorModelUrls[pieceKey];

                if (!this.pieces[pieceKey])
                    this.pieces[pieceKey] = new ArmorPiece(scene, pieceKey == "leggings" ? 2 : 1, pieceKey, extraTool);

                const updateMeshWithTextureURL = `textures/armors/${paths.fixMaterialNameGolden(material)}_layer_${pieceKey == "leggings" ? "2" : "1"}.png`
                const newObj = new Object3D().objFromURL(url).updateMeshWithTexture(updateMeshWithTextureURL, false).setScene(scene);
                // console.log(newObj);
                this.pieces[pieceKey].models[material] = newObj;
                // newObj.animate_top_texure(glint);

            }
            // this.pieces[pieceKey].updateTexture();
        }

        this.addModel("helmet", "turtle", armorModelUrls["helmet"], "textures/armors/turtle_layer_1.png", scene);
        this.addModel("chestplate", "elytra", armorModelUrls["elytra"], "textures/armors/elytra.png", scene);
    }

    addModel(pieceKey, nameKey, url, textureUrl, scene) {

        const newObj = new Object3D().objFromURL(url).updateMeshWithTexture(textureUrl, true).setScene(scene);
        this.pieces[pieceKey].models[nameKey] = newObj;
    }
}