export default class PopupsHandler {

    constructor() {

        this.popupHolder = document.getElementById("popups-holder");
        /** @type {HTMLDivElement} */
        this.darkBack = document.getElementById("dark-back");
    
        this.setupPopups();
        this.closeAllPopups();
        // this.openPopup("custom-leather-color");
    }

    setupPopups() {

    }

    /**
     * @param {String} id 
     */
    openPopup(id) {

        this.closeAllPopups();

        const elem = document.getElementById(id);

        elem.classList.remove("hidden");
    }

    closeAllPopups() {

        for (const popup of this.popupHolder.children) {

            if (!popup.classList.contains("hidden"))
                popup.classList.add("hidden");
        }

        this.darkBack.classList.add("hidden");
    }

    closeDarkBack(){


    }

    openDarkBack(){


    }
}