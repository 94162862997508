import Object3D from "./object3d";
import SceneHandler from "./scene_handler";

export default class PlayerModel {

    
    constructor(sceneHandler) {

        /** @type {SceneHandler} */
        this._sceneHandler = sceneHandler;
        this.layer_modifier = "_layers";
    }

    setModel(url, layerUrl) {
        console.log(url);
        
        
        this.modelUrl = url;
        this.modelLayerUrl = layerUrl;

        // this.modelUrl = new URL(url, import.meta.url);
        // this.modelLayerUrl = new URL(url + this.layer_modifier, import.meta.url);
        this.player = new Object3D().objFromURL(this.modelUrl)

        this._sceneHandler.mainScene.addObj(this.player);

        return this;
    }

    setSkin(skinPath) {
        
        // if(this.player)
            // this._sceneHandler.mainScene.removeObj(this.player);

        if(this.playerLayer)
            this._sceneHandler.mainScene.removeObj(this.playerLayer);

        // this.player = new Object3D().objFromURL(this.modelUrl).updateMeshWithTexture(skinPath, true);
        this.player.setObjTexture(skinPath);
        this.playerLayer = new Object3D().objFromURL(this.modelLayerUrl).updateMeshWithTexture(skinPath, true);

        this._sceneHandler.mainScene.addObj(this.playerLayer);
    }
}