import Paths from "./paths";

export default class LeatherMaker {

    getTexture(color, piece_layer) {

        return new Promise((resolve, reject) => {

            const pieceImg = new Image();
            const pieceOverlay = new Image();

            pieceImg.src = `textures/armors/leather_layer_${piece_layer}.png`;
            pieceOverlay.src = `textures/armors/leather_layer_${piece_layer}_overlay.png`;

            Promise.all([new Promise(resolve => pieceImg.onload = resolve), new Promise(resolve => pieceOverlay.onload = resolve)]).then(() => {

                const finalImgCanvas = document.createElement('canvas');
                finalImgCanvas.width = pieceImg.width;
                finalImgCanvas.height = pieceImg.height;

                const ctx = finalImgCanvas.getContext('2d');
                ctx.drawImage(pieceImg, 0, 0);

                const ctxDatas = ctx.getImageData(0, 0, pieceImg.width, pieceImg.height);

                const ctxData = ctxDatas.data;

                let colorRGB = typeof color == "object" ? color : new Paths().getDyeColor(color);

                //loop over and all the pixels in grayscale.
                for (let i = 0; i < pieceImg.width * pieceImg.height * 4; i += 4) {

                    ctxData[i + 0] = (ctxData[i + 0] * colorRGB[0]) / 255;
                    ctxData[i + 1] = (ctxData[i + 1] * colorRGB[1]) / 255;
                    ctxData[i + 2] = (ctxData[i + 2] * colorRGB[2]) / 255;
                }


                ctx.putImageData(ctxDatas, 0, 0);
                ctx.drawImage(pieceOverlay, 0, 0, pieceImg.width, pieceImg.height);
                const dataURL = finalImgCanvas.toDataURL();
                resolve(dataURL);

            }).catch(reject);
        });
    }
}