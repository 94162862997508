export default class Resolver {

    constructor() {


    }

    promsifie(func) {

        const prom = typeof func.then == "function" ? func : new Promise(() => func)
        
        prom.catch((error) => {
            console.error('A promise was rejected:', error);
        });

        return prom;
    }
}