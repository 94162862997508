import ColorHandler from "./ColorHandler.js";
import CookiesHandler from "./CookiesHandler";
import UrlHandler from "./UrlHandler.js";
import ArmorPiecesHandler from "./armor_pieces_handler";
import HtmlGen from "./html_gen";
import Paths from "./paths";

export default class TrimSetsHandler {

    /**
     * @param {ArmorPiecesHandler} armorPiecesHandler 
     */
    constructor(armorPiecesHandler) {

        for (const piece of Object.keys(armorPiecesHandler.pieces)) {

            armorPiecesHandler.pieces[piece].trimSetsHandler = this;
        }

        this.trimSetsHolder = document.getElementById("sets-holder");
        this.deleteBtn = document.getElementById("trim-delete")
        this.armorPiecesHandler = armorPiecesHandler;
        this.cookieHandler = new CookiesHandler();

        this.trimSets = [];
        this.currSetIndex = 0;
        this.lim = 100;
        this.hasUrlSet = false;
        this.namingIndex = 0;

        this.clickFunc = () => {

            // console.log("asd", this);

            if (this.trimSets.length >= this.lim)
                return;

            this.addTrim(this.getSetFrom3DModel())

            this.setCurrTrim(this.trimSets.length - 1);
        }

        // this.addElemSet = JSON.parse(JSON.stringify(this.trimSetsHolder.children[0]));
        this.newAddBtn();
        this.loadSetsOnStart();

        this.setCurrTrim(!this.hasUrlSet ? 0 : this.trimSets.length - 1);

        this.deleteBtn.addEventListener("click", () => {

            this.deleteCurrTrim();
            console.log("del");
        });
    }

    addTrim(trimSet) {

        this.trimSets.push(trimSet);
        this.setupLoadTrimSetBtn(trimSet);
        this.saveTrimToCookies(this.trimSets.indexOf(trimSet), trimSet)
    }

    loadSetsOnStart() {

        const setsToAdd = [];

        for (let i = 0; i < this.lim; i++) {

            const currSet = this.getSetFromCookie(i)

            if (currSet.helmet.material == "" || currSet.helmet.material == "undefined")
                break;

            setsToAdd.push(currSet)
        }

        const urlSet = this.getSetFromUrl();

        if (urlSet != undefined)
            setsToAdd.push(urlSet);

        if (setsToAdd.length == 0)
            setsToAdd.push(this.getSetFromRandom());

        let i = 0
        for (const currSet of setsToAdd) {

            // currSet.index = i;
            this.addTrim(currSet);
            i++;
        }
    }

    getSetFrom3DModel() {

        // const i = this.trimSets.length;

        const newSet = {
            name: "Set " + this.getNameIndex(),
            //  index: this.trimSets.length
        };

        let pieceTypes = ["helmet", "chestplate", "leggings", "boots"]
        // let pieceObjects = [helmetObj, chestplateObj, leggingsObj, bootsObj]

        let index = 0;
        console.log(this.armorPiecesHandler.pieces);

        for (const pieceType of pieceTypes) {

            newSet[pieceType] = {};
            newSet[pieceType].material = this.armorPiecesHandler.pieces[pieceType].pieceMaterial;
            newSet[pieceType].pattern = this.armorPiecesHandler.pieces[pieceType].trimModel;
            newSet[pieceType].palette = this.armorPiecesHandler.pieces[pieceType].trimPalette;
            newSet[pieceType].dyeColor = this.armorPiecesHandler.pieces[pieceType].dyeColor;

            index++;
        }

        return newSet;
    }

    getNameIndex() {

        this.namingIndex++;
        return this.namingIndex;
    }

    getSetFromUrl() {

        const URLData = new URLSearchParams(window.location.search);

        if (URLData.get("helmet") == null)
            return undefined;

        this.hasUrlSet = true;
        this.lim++;
        const setFromUrl = { name: "url set" };

        const paths = new Paths();

        for (const armorPieceType of paths.pieceTypes) {

            const urlPiece = URLData.get(armorPieceType);

            if (URLData != null) {
                this.hasUrlSet = true;
                // console.log("aallah");
                const parts = urlPiece.split("_");

                // console.log(parts, parts.length >= 4);
                
                setFromUrl[armorPieceType] =
                {
                    material: paths.findSubStringInArray(parts[0], paths.pieceMaterials),
                    pattern: paths.findSubStringInArray(parts[1], paths.trimModels),
                    palette: paths.findSubStringInArray(parts[2], paths.trimPalettes),
                    dyeColor: parts.length >= 4 ? new ColorHandler().smartCastToRGBArray(parts[3]) : [255, 255, 255]
                };
            }
        }
        new UrlHandler().removeUrl();
        return setFromUrl;
    }

    getSetFromCookie(i) {

        // console.log(i);
        const loadedSet = {};
        loadedSet.name = "Set " + this.getNameIndex();

        for (const armorPieceType of new Paths().pieceTypes) {

            loadedSet[armorPieceType] = {};
            loadedSet[armorPieceType].material = this.cookieHandler.getCookie(armorPieceType + "_material" + i);
            loadedSet[armorPieceType].pattern = this.cookieHandler.getCookie(armorPieceType + "_pattern" + i);
            loadedSet[armorPieceType].palette = this.cookieHandler.getCookie(armorPieceType + "_palette" + i);
            loadedSet[armorPieceType].dyeColor = this.cookieHandler.getCookie(armorPieceType + "_dyeColor" + i);
        }

        return loadedSet;
    }

    getSetFromRandom() {

        const loadedSet = {};
        loadedSet.name = "Set " + this.getNameIndex();

        for (const armorPieceType of new Paths().pieceTypes) {

            loadedSet[armorPieceType] = {};
            loadedSet[armorPieceType].material = new Paths().pieceMaterials[Math.floor(Math.random() * new Paths().pieceMaterials.length)];
            loadedSet[armorPieceType].pattern = new Paths().trimModels[Math.floor(Math.random() * new Paths().trimModels.length)];
            loadedSet[armorPieceType].palette = new Paths().trimPalettes[Math.floor(Math.random() * new Paths().trimPalettes.length)];
            const dyeColors = Object.keys(new Paths().getDyeColors());
            console.log(dyeColors[Math.floor(Math.random() * dyeColors.length)]);
            
            loadedSet[armorPieceType].dyeColor = dyeColors[Math.floor(Math.random() * dyeColors.length)];
        }

        return loadedSet;
    }

    setupLoadTrimSetBtn(trimSetToDisp) {

        let set = this.addElemSet;

        this.addElemSet.removeAttribute("id");
        this.addElemSet.innerHTML = trimSetToDisp.name;
        this.addElemSet.removeEventListener("click", this.clickFunc)
        this.addElemSet.addEventListener("click", () => {

            let index = [...this.trimSetsHolder.children].indexOf(set);
            this.setCurrTrim(index);
        })
        this.newAddBtn();
    }

    deleteCurrTrim() {

        this.deleteTrim(this.currSetIndex);
    }

    deleteTrim(removeIndex) {

        if (this.trimSets.length == 1)
            return;

        for (let i = removeIndex; i < this.trimSets.length; i++) {

            const trimSet = i == this.trimSets.length - 1 ? undefined : this.trimSets[i + 1];

            for (const pieceType of new Paths().pieceTypes) {

                if (i == this.trimSets.length - 1) {

                    this.cookieHandler.removeCookie(pieceType + "_material" + i);
                    this.cookieHandler.removeCookie(pieceType + "_pattern" + i);
                    this.cookieHandler.removeCookie(pieceType + "_palette" + i);
                    this.cookieHandler.removeCookie(pieceType + "_dyeColor" + i);
                    break;
                }

                this.cookieHandler.overrideCookie(pieceType + "_material" + i, trimSet[pieceType].material);
                this.cookieHandler.overrideCookie(pieceType + "_pattern" + i, trimSet[pieceType].pattern);
                this.cookieHandler.overrideCookie(pieceType + "_palette" + i, trimSet[pieceType].palette);
                this.cookieHandler.overrideCookie(pieceType + "_dyeColor" + i, trimSet[pieceType].dyeColor);
            }
        }

        this.trimSets.splice(removeIndex, 1);
        this.trimSetsHolder.removeChild(this.trimSetsHolder.children[this.trimSetsHolder.children.length - 2]);
        this.setCurrTrim(this.currSetIndex == this.trimSets.length ? this.trimSets.length - 1 : this.currSetIndex);

        let i = 0
        for (const trimSet of this.trimSets) {

            this.trimSetsHolder.children[i].innerText = trimSet.name;

            i++;
        }
    }

    updateTrim(i, piece, material, pattern, palette, dyeColor) {

        this.trimSets[i][piece].material = material;
        this.trimSets[i][piece].pattern = pattern;
        this.trimSets[i][piece].palette = palette;
        this.trimSets[i][piece].dyeColor = dyeColor;

        this.cookieHandler.overrideCookie(piece + "_material" + i, material);
        this.cookieHandler.overrideCookie(piece + "_pattern" + i, pattern);
        this.cookieHandler.overrideCookie(piece + "_palette" + i, palette);
        this.cookieHandler.overrideCookie(piece + "_dyeColor" + i, dyeColor);
    }

    updateCurrTrim(piece, material, pattern, palette, dyeColor) {

        // console.log(dyeColor);
        this.updateTrim(this.currSetIndex, piece, material, pattern, palette, dyeColor)
    }

    setCurrTrim(index) {

        this.currSetIndex = index;

        for (const child of this.trimSetsHolder.children)
            child.classList.remove("selected-trim");

        this.trimSetsHolder.children[this.currSetIndex].classList.add("selected-trim");

        const currSet = this.trimSets[this.currSetIndex];

        for (const pieceType of new Paths().pieceTypes) {

            // console.log("------------------------------");
            // console.log(currSet[pieceType].material);
            // console.log(currSet[pieceType].pattern);
            // console.log(currSet[pieceType].palette);
            // console.log("------------------------------");

            // console.log("curr t", currSet[pieceType]);

            this.armorPiecesHandler.pieces[pieceType].setArmorMaterial(currSet[pieceType].material, true);
            this.armorPiecesHandler.pieces[pieceType].setTrimModel(currSet[pieceType].pattern, true);
            this.armorPiecesHandler.pieces[pieceType].setTrimPalette(currSet[pieceType].palette, true);
            this.armorPiecesHandler.pieces[pieceType].setLeatherColor(currSet[pieceType].dyeColor);
            this.armorPiecesHandler.pieces[pieceType].updateDivs();
        }

        this.deleteBtn.style.top = ((90) * (index) + 10) + "px";
    }

    saveTrimToCookies(i, trimSet) {

        let pieceTypes = ["helmet", "chestplate", "leggings", "boots"]

        for (const pieceType of pieceTypes) {
            this.cookieHandler.overrideCookie(pieceType + "_material" + i, trimSet[pieceType].material);
            this.cookieHandler.overrideCookie(pieceType + "_pattern" + i, trimSet[pieceType].pattern);
            this.cookieHandler.overrideCookie(pieceType + "_palette" + i, trimSet[pieceType].palette);
            this.cookieHandler.overrideCookie(pieceType + "_dyeColor" + i, trimSet[pieceType].dyeColor);
        }
    }

    newAddBtn() {

        const htmlGen = new HtmlGen();

        if (this.trimSets.length >= this.lim)
            return;

        this.addElemSet = document.createElement("div");
        this.addElemSet.id = "add-trim-set-btn"
        const circle = htmlGen.createElem("div", "", this.addElemSet, "", []);
        htmlGen.createElem("div", "", circle, "", []);
        htmlGen.createElem("div", "", circle, "", []);

        this.addElemSet.addEventListener("click", this.clickFunc);

        this.trimSetsHolder.appendChild(this.addElemSet);
    }
}