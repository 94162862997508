import * as THREE from "three";
import ImageEditor from "./image_editor";
import Object3D from "./object3d";
import Scene from "./scene";
import Paths from "./paths";

import glint from "./../textures/enchanted_glint_entity.png";
import LeatherMaker from "./leather_maker";
import Resolver from "./Resolvers";
import ColorHandler from "./ColorHandler";
import ExtraTool from "./ExtraTool";

/**
 * Represents an ArmorPiece in the scene.
 * @class
 */
export default class ArmorPiece {

    /**
     * @param {Scene} scene 
     */
    /**
     * @param {Scene} scene - The scene to add the armor piece to.
     * @param {number} textureLayer - The layer of the texture to use.
     * @param {string} pieceName - The name of the piece.
     * @param {ExtraTool} extraTool - extra tool.
     */     
    
    constructor(scene, textureLayer, pieceName, extraTool) {

        this.models = {};
        this.textureLayer = textureLayer;
        this.pieceMaterial = "leather";
        this.trimModel = "dune";
        this.trimPalette = "copper";
        this._dyeColor = [200, 0, 0];
        this.scene = scene;
        this.extraTool = extraTool;

        this.loadedTextures = {};

        /**
         * @type {HTMLElement[]}
         */
        this.htmlEdits = [];
        this.pieceName = pieceName;
        // this.trimSetsHandler = ""

        // this.updateTexture();
    }

    get dyeColor() {

        // console.trace("get dye:", this._dyeColor);
        return this._dyeColor;
    }

    set dyeColor(color) {

        // console.trace("set dye:", color);

        if(typeof color != "object")
            console.log("color cant be in this format! color:", color);

        if(color.length != 3)
            console.log("color length incorrect! coloar:", color);

        this._dyeColor = color;
    }

    updateDivs() {

        this._updateMaterialDiv(this.pieceMaterial);
        this._updateTrimModelDiv(this.trimModel);
        this._updateTrimPaletteDiv(this.trimPalette);
        this._updateLeatherColorDiv(this.dyeColor);
    }

    getGiveCommands() {

        // let java = `/give @p ${this.pieceMaterial}_${this.pieceName}[minecraft:trim={pattern:'${this.trimModel}',material:'${this.trimPalette}'}]`

        // if(this.trimModel == "empty")
            // java = `/give @p ${this.pieceMaterial}_${this.pieceName}`

        const java_trim = this.trimModel == "empty" ? "" : `trim={pattern:'${this.trimModel}',material:'${this.trimPalette}'},`
        // console.log(this._dyeColor);
        
        const java_color = this.pieceMaterial == "leather" ? `dyed_color={rgb:${new ColorHandler().RGBArrayToDecimal(this._dyeColor)}},` : "";
        

        return {

            "java": `/give @p ${this.pieceMaterial}_${this.pieceName}[${java_trim}${java_color}]`,
            "bedrock": `Not possible.`,
        }
    }

    _updateMaterialDiv(material) {

        this.updateDiv(this.htmlEdits[0], new Paths().getMaterialPath(material, this.pieceName).icon);

    }

    _updateTrimModelDiv(trimModel) {

        this.updateDiv(this.htmlEdits[2], new Paths().getTrimPath(trimModel).icon);
    }

    _updateTrimPaletteDiv(trimPalette) {

        this.updateDiv(this.htmlEdits[3], new Paths().getPalettePath(trimPalette).icon);
    }

    _updateLeatherColorDiv(color) {

        
        let convertedColor =  new ColorHandler().closestColor(color, Object.keys(new Paths().getDyeColors()));
        
        let allColors = new Paths().getDyeColors();

        for (const dyeKey of Object.keys(allColors)) {
            
            let dyeValue = new ColorHandler().decimalToRGBArray(allColors[dyeKey]);
            
            if(JSON.stringify(convertedColor) == JSON.stringify(dyeValue)){

                convertedColor = dyeKey;
                break;
            }
        }
        // console.log(convertedColor);

        this.updateDiv(this.htmlEdits[1], new Paths().getDyePath(convertedColor).icon);
    }

    makeUrl(){

        const strLim = 3;

        return `${this.pieceName}=${this.pieceMaterial.substring(0, strLim)}_${this.trimModel.substring(0, strLim)}_${this.trimPalette.substring(0, strLim)}_${this.dyeColor}`;

        // return `${this.pieceName}=${this.pieceMaterial}_${this.trimModel}_${this.trimPalette}_${this.dyeColor}`;
    }

    updateDiv(div, src) {

        // console.log(src);

        if (div)
            div.children[0].src = src;
    }

    setArmorMaterial(material, dontUpdate) {

        if (material == "gold")
            material = "golden"

        this.pieceMaterial = material;

        if (!dontUpdate)
            this.updateTexture();
    }

    setTrimModel(model, dontUpdate) {

        this.trimModel = model;

        if (!dontUpdate)
            this.updateTexture();
    }

    setTrimPalette(palette, dontUpdate) {

        this.trimPalette = palette;

        if (!dontUpdate)
            this.updateTexture();
    }

    setLeatherColor(color, dontUpdate) {

        if(typeof color != "object")
            color = new ColorHandler().smartCastToRGBArray(color);

        // console.log("col:", color);
        // console.trace("casted color:", color);

        this.dyeColor = color;

        if (!dontUpdate)
            this.updateTexture();
    }

    updateTexture() {

        // console.trace("update texture");

        if (this.trimSetsHandler) {

            // console.log(this.pieceName, this.pieceMaterial, this.trimModel, this.trimPalette);
            this.trimSetsHandler.updateCurrTrim(this.pieceName, this.pieceMaterial, this.trimModel, this.trimPalette, this.dyeColor);
        }

        //removes loaded armor piece model so a new one can be put in. 
        //TODO: Add so this only happens if piece material is changed.
        if (this.loadedModel) {

            this.scene.removeObj(this.loadedModel);
        }

        /**
         * @type {Object3D}
         */
        const object3d = this.models[this.pieceMaterial];
        const model = this.models[this.pieceMaterial].model;

        let textureDataUrl;

        //TODO check if stored textures is really used? Set a limit to how many can be used and makje sure it uses leather
        //Probably more stuff needs to be fixed!
        if (this.trimModel in this.loadedTextures && this.trimPalette in this.loadedTextures[this.trimModel])
            textureDataUrl = this.loadedTextures[this.trimModel][this.trimPalette];

        else {

            let materialNameFixed = this.pieceMaterial == "golden" ? "gold" : this.pieceMaterial;
            let materialUrl = `textures/armors/${materialNameFixed}_layer_${this.textureLayer}.png`
            let trimUrl = `textures/trims/${this.trimModel}${this.textureLayer == 2 ? "_leggings" : ""}.png`
            let paletteUrl = `textures/palettes/${this.trimPalette}${this.trimPalette == materialNameFixed ? "_darker" : ""}.png`

            // console.log(materialUrl);
            // console.log(trimUrl);
            // console.log(paletteUrl);
            // console.log("-------------");

            let newTextureData;

            const applyTextureToModel = textureDataUrl => {

                if (object3d.textureAnimation) {

                    object3d.textureAnimation.setup(textureDataUrl, glint);
                }

                new THREE.TextureLoader().load(textureDataUrl,
                    texture => {

                        texture.minFilter = THREE.NearestFilter;
                        texture.magFilter = THREE.NearestFilter;
                        texture.colorSpace = THREE.SRGBColorSpace;

                        if (typeof model.then == "function")
                            model.then(loaded => {

                                for (const box of loaded.children) {
                                    box.material = new THREE.MeshPhongMaterial({
                                        map: texture,
                                        // transparent: true,
                                        side: THREE.DoubleSide
                                    });
                                }
                            });
                        else {

                            for (const box of model.children) {
                                box.material = new THREE.MeshPhongMaterial({
                                    map: texture,
                                    // transparent: true,
                                    side: THREE.DoubleSide
                                });
                            }
                        }
                    }
                );
            }

            if (materialNameFixed == "leather") {

                // console.log("d color:",this._dyeColor);
                newTextureData = new LeatherMaker().getTexture(this.dyeColor, this.textureLayer);
                new Resolver().promsifie(newTextureData).then(result => {

                    new ImageEditor().createTrimmedArmorTexture(result, trimUrl, paletteUrl).then(applyTextureToModel);
                });
            }
            else {

                newTextureData = new ImageEditor().createTrimmedArmorTexture(materialUrl, trimUrl, paletteUrl);
                newTextureData.then(applyTextureToModel);
            }
        }

        this.scene.addObj(object3d);
        this.loadedModel = object3d;

        // this.scene.removeObj(this.models[Object.keys(this.models)[4]].model);

        if (this.htmlEdits.length > 1)
            if (this.pieceMaterial == "leather")
                this.htmlEdits[1].parentElement.classList.remove("hide-leather");
            else
                this.htmlEdits[1].parentElement.classList.add("hide-leather");

        this.extraTool.urlHandler.updateGenUrlObj(this.pieceName, this.makeUrl());
        this.extraTool.trimInfoLoader.updateSelected(this.pieceName, this.trimModel);
    }
} 