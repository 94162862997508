export default class TextureAmim {

    setup(texture1Url, texture2Url) {

        this.offset = 0;
        this.lastTime = Date.now();
        this.doneLoading = false;

        this.texture1Img = new Image();
        this.texture2Img = new Image();

        this.texture1Img.src = texture1Url;
        // this.texture1Img.src = this.test_texture;
        this.texture2Img.src = texture2Url;

        let promises = [

            new Promise(resolve => this.texture1Img.onload = resolve),
            new Promise(resolve => this.texture2Img.onload = resolve)
        ];

        Promise.all(promises).then(() => {

            this.doneLoading = true;

            /**
             * @type {HTMLCanvasElement}
             */
            this.canvas = document.createElement('canvas');

            this.canvas.width = this.texture1Img.width;
            this.canvas.height = this.texture1Img.height;

            /**
             * @type {CanvasRenderingContext2D}
             */
            this.ctx = this.canvas.getContext('2d');
        })

        return this;
    }

    tickAnim() {

        // console.log("asd");
        if (!this.doneLoading)
            return undefined;

        let currTime = Date.now();
        let deltaTime = currTime - this.lastTime;
        this.lastTime = currTime;

        this.offset += deltaTime/1000 * 10;

        // ---------------------------------------------------

        const canvas = this.canvas;
        const ctx = this.ctx;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.globalAlpha = 1.0;
        
        ctx.drawImage(this.texture1Img, 0, 0);
        // ctx.drawImage(this.test_texture, 0, 0);
        // ctx.drawImage(this.texture2Img, 0, 0);
        ctx.globalAlpha = 0.3;
        // const offset = Math.floor(this.offset) % this.texture2Img.width;
        const offset = this.offset % this.texture2Img.width;
        ctx.drawImage(this.texture2Img, offset, offset, this.texture2Img.width, this.texture2Img.height);
        ctx.drawImage(this.texture2Img, offset - this.texture2Img.width, offset - this.texture2Img.height, this.texture2Img.width, this.texture2Img.height);
        ctx.drawImage(this.texture2Img, offset, offset - this.texture2Img.height, this.texture2Img.width, this.texture2Img.height);
        ctx.drawImage(this.texture2Img, offset - this.texture2Img.width, offset, this.texture2Img.width, this.texture2Img.height);

        // const trimImageData = ctx.getImageData(0, 0, texture2Img.width, texture2Img.height);

        // ctx.putImageData(trimImageData, 0, 0);
        const dataURL = canvas.toDataURL();
        // resolve(dataURL);
        return dataURL;
    }

    // combine_2_textures() {

    //     return new Promise((resolve, reject) => {

    //         Promise.all([new Promise(resolve => texture1Img.onload = resolve), new Promise(resolve => texture2Img.onload = resolve)]).then(() => {

    //             const canvas = document.createElement('canvas');
    //             const trimCanvas = document.createElement('canvas');
    //             canvas.width = texture1Img.width;
    //             canvas.height = texture1Img.height;

    //             const ctx = canvas.getContext('2d');
    //             const trimCtx = trimCanvas.getContext('2d');

    //             ctx.drawImage(texture1Img, 0, 0);
    //             ctx.drawImage(texture2Img, 0, 0, texture1Img.width, texture1Img.height);

    //             trimCtx.drawImage(texture2Img, 0, 0, texture1Img.width, texture1Img.height);

    //             const trimImageData = ctx.getImageData(0, 0, texture2Img.width, texture2Img.height);
    //             const splitFullImg = trimImageData.data;
    //             const splitTrimImg = trimCtx.getImageData(0, 0, texture2Img.width, texture2Img.height).data;

    //             const paletteCanvas = document.createElement('canvas');
    //             paletteCanvas.width = paletteImg.width;
    //             paletteCanvas.height = paletteImg.height;
    //             const paletteCtx = paletteCanvas.getContext('2d');
    //             paletteCtx.drawImage(paletteImg, 0, 0);
    //             const paletteData = paletteCtx.getImageData(0, 0, paletteImg.width, paletteImg.height).data;

    //             for (let i = 0; i < splitTrimImg.length; i += 4) {
    //                 const grayValue = splitTrimImg[i];
    //                 const opacity = splitTrimImg[i + 3];

    //                 if (opacity == 0)
    //                     continue;

    //                 // 8
    //                 const colorIndex = 7 - grayValue / 32;

    //                 splitFullImg[i] = paletteData[colorIndex * 4];
    //                 splitFullImg[i + 1] = paletteData[colorIndex * 4 + 1];
    //                 splitFullImg[i + 2] = paletteData[colorIndex * 4 + 2];
    //             }

    //             ctx.putImageData(trimImageData, 0, 0);
    //             const dataURL = canvas.toDataURL();
    //             resolve(dataURL);

    //         }).catch(reject);
    //     });
    // }
}