
import Scene from './scene';
export default class SceneHandler {

    constructor() {

        this.mainScene = "not set";
    }

    newSceneSet(canvasHolder){
        
        /** @type {Scene} */
        this.mainScene = new Scene(canvasHolder); 
    }
}