// import THREE from "./three_import.js";
import * as THREE from "three";


export default class Camera {

    constructor(){

        this.x = 3;
    }
}