import ColorHandler from "./ColorHandler";
import ArmorPiece from "./armor_piece";
import HtmlGen from "./html_gen";
import Paths from "./paths";

export default class AdvancedMenu {

    /** @param {HTMLDivElement} menu*/
    constructor(menu) {

        this.trackedMenuOpeners = [];
        /** @type {Array<HTMLImageElement>} */
        this.activeDyes = []

        /** @type {HTMLDivElement} */
        this.menu = menu;
        this.setup(this.menu.children[1].children[1]);
        this.close();
        this.colorListeners = [];
        this.colUpdateSpeed = 1000 / 30;
        this.activeArmorPieces = [];
    }

    setup(holder) {

        const htmlGen = new HtmlGen();

        this.createLeatherDropdown(holder);
        htmlGen.createElem("p", "+", holder);
        this.createLeatherDropdown(holder);
        htmlGen.createElem("p", "or", holder);

        /** @type {HTMLInputElement} */
        this.colorInp = htmlGen.createElem("input", "", holder);
        this.colorInp.type = "color";
    }

    /**
     * Creates a leather dropdown menu.
     * 
     * @param {HTMLElement} holder - The container element for the dropdown menu.
     * @returns {void}
     */
    createLeatherDropdown(holder) {

        const htmlGen = new HtmlGen();

        const slotDiv = htmlGen.createElem('div', '', holder, "", ["dropdown-box"]);
        const dispImg = htmlGen.createElem('img', '', slotDiv, "selected-disp");
        const dropdownDiv = htmlGen.createElem('div', '', slotDiv, '', ['dropdown', "hidden"]);
        dispImg.addEventListener("click", () => { htmlGen.dropdownEvent(dropdownDiv) });

        this.activeDyes.push(dispImg);

        const paths = new Paths();

        for (const dye of Object.keys(paths.getDyeColors())) {

            const img = htmlGen.createElem("img", "", dropdownDiv);

            img.src = paths.getDyePath(dye).icon;
            const colHandler = new ColorHandler();

            img.addEventListener("mouseenter", () => {

                /** @type {ArmorPiece} */
                let piece;

                dispImg.setAttribute("color", dye);

                for (piece of this.activeArmorPieces) {

                    piece.setLeatherColor(
                        //convert to rgb color (or hex if needed)
                        //do same for [1] 
                        // send into combiner
                        colHandler.colorCombiner(

                            paths.getDyeColor(this.activeDyes[0].getAttribute("color")),
                            paths.getDyeColor(this.activeDyes[1].getAttribute("color"))
                        )
                    );
                }

                dispImg.src = img.src;
            });

            img.addEventListener("click", () => {

                htmlGen.dropdownEvent(dropdownDiv)
            });

            dispImg.src = img.src;
            dispImg.setAttribute("color", dye);

        }
    }

    /** @param {Array<ArmorPiece>} armorPieces  */
    open(armorPieces) {

        this.activeArmorPieces = armorPieces;

        for (const listener of this.colorListeners) {

            this.colorInp.removeEventListener("input", listener.listener)
        }

        this.colorListeners = [];

        for (const armorPiece of armorPieces) {

            for (const div of this.trackedMenuOpeners) {

                div.classList.remove("burger-menu-open");
            }

            /** @type {HTMLDivElement} */
            const armorHolder = this.menu.children[0];
            // console.log(armorPiece.htmlEdits[0]);
            // console.log(armorHolder);

            armorHolder.innerHTML = "";

            armorHolder.appendChild(this.cloneNodeWithListeners(armorPiece.htmlEdits[0]));
            armorHolder.appendChild(this.cloneNodeWithListeners(armorPiece.htmlEdits[2]));
            armorHolder.appendChild(this.cloneNodeWithListeners(armorPiece.htmlEdits[3]));

            // console.log(this.getEventListeners(armorPiece));

            // armorHolder.innerHTML = armorPiece.htmlEdits[0].innerHTML;
            // armorHolder.appendChild(armorPiece.htmlEdits[0].cloneNode(true))
            // armorHolder.appendChild(armorPiece.htmlEdits[0]);

            // new Resolver().promsifie(armorPiece.htmlEdits).then(() => {

            // });

            const javaCommand = document.getElementById("java-give-command");

            javaCommand.value = armorPiece.getGiveCommands().java;

            javaCommand.addEventListener("click", () => {

                javaCommand.select();
                javaCommand.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(javaCommand.value);
            });

            let solidArmorPiece_DONT_SIMPLIFY = armorPiece;
            const i = this.colorListeners.length;

            const newListener = e => {

                const color = new ColorHandler().hexToRGBArray(e.target.value);

                if (Date.now() - this.colorListeners[i].last_time > this.colUpdateSpeed) {

                    this.colorListeners[i].last_time = Date.now();
                    solidArmorPiece_DONT_SIMPLIFY.setLeatherColor(color);
                    solidArmorPiece_DONT_SIMPLIFY._updateLeatherColorDiv(solidArmorPiece_DONT_SIMPLIFY.dyeColor);

                }
            }

            this.colorListeners.push({

                "listener": newListener,
                "last_time": 0
            });

            this.colorInp.addEventListener("input", newListener);
            this.colorInp.value = new ColorHandler().RGBArrayToHex(armorPiece.dyeColor);

            // const bedrockCommand = document.getElementById("java-give-command");
            // bedrockCommand.value = armorPiece.getGiveCommands().bedrock;

            this.menu.classList.remove("hidden");
        }

        console.log("lsitners: ", this.colorListeners);
    }

    close() {

        this.menu.classList.add("hidden");
    }

    cloneNodeWithListeners(element) {

        var clone = element.cloneNode(true);
        this.cloneListeners(element, clone);

        return clone;
    }

    cloneListeners(source, target) {
        var listeners = this.getEventListeners(source);
        // console.log(listeners);
        listeners.forEach(function (event) {
            target.addEventListener(event.type, event.listener);
        });

        for (var i = 0; i < source.children.length; i++) {
            this.cloneListeners(source.children[i], target.children[i]);
        }
    }

    getEventListeners(element) {
        var listeners = [];

        // For each possible event type
        var eventTypes = [
            'click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mousemove', 'mouseout', 'mouseenter', 'mouseleave',
            'keydown', 'keypress', 'keyup',
            'focus', 'blur', 'change', 'input', 'submit', 'reset',
            'resize', 'scroll', 'wheel', 'contextmenu', 'select', 'pointerdown', 'pointerup', 'pointermove'
        ];

        eventTypes.forEach(function (eventType) {
            var event = element[eventType];
            if (event) {
                listeners.push({ type: eventType, listener: event });
            }
        });

        return listeners;
    }
}