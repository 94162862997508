import Paths from "./paths.js";

export default class ColorHandler {

    /**
    * Convert a decimal number representing an RGB color into an array of RGB components.
    * 
    * @param {number} decimal - The decimal number representing the RGB color.
    * @returns {Array<number>} An array containing the RGB components [r, g, b].
    */
    decimalToRGBArray(decimal) {

        let r = Math.floor(decimal / (256 * 256));
        let g = Math.floor(decimal / 256) % 256;
        let b = decimal % 256;

        return [r, g, b];
    }

    RGBArrayToDecimal(arr){

        return arr[0] * 256 * 256 + arr[1] * 256 + arr[2];
    }

    smartCastToRGBArray(color) {

        if (typeof color == "object") {

            // console.log("no cast needed here?");
            // console.log(color.length);
            return color;
        }

        //return rgb array as numbers
        if (color.split(",").length == 3)
            return color.split(",").map(x => parseInt(x));

        if (Object.keys(new Paths().getDyeColors()).includes(color))
            return new Paths().getDyeColor(color)

        return this.decimalToRGBArray(color);
    }

    /**
    * Convert a hex color code into an array of RGB components.
    *
    * @param {string} hex - The hex color code to convert.
    * @returns {Array<number>} An array containing the RGB components [r, g, b].
    */
    hexToRGBArray(hex) {
        hex = hex.replace('#', '');

        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        let r = parseInt(hex.slice(0, 2), 16);
        let g = parseInt(hex.slice(2, 4), 16);
        let b = parseInt(hex.slice(4, 6), 16);

        return [r, g, b];
    }

    RGBArrayToHex(arr) {

        arr[0] = Math.max(0, Math.min(255, arr[0]));
        arr[1] = Math.max(0, Math.min(255, arr[1]));
        arr[2] = Math.max(0, Math.min(255, arr[2]));

        const toHex = (value) => value.toString(16).padStart(2, '0');

        return `#${toHex(arr[0])}${toHex(arr[1])}${toHex(arr[2])}`;
    }

    /**
     * 
     * @param {*} col1 hex or rgb array
     * @param {*} col2 hex or rgb array
     * @returns rgb array
     */
    colorCombiner(col1, col2) {

        console.log(col1);
        console.log(col2);

        if (typeof col1 != "object")
            col1 = this.hexToRGBArray(col1)

        if (typeof col2 != "object")
            col2 = this.hexToRGBArray(col2)

        const combined = [

            (col1[0] + col2[0]) / 2,
            (col1[1] + col2[1]) / 2,
            (col1[2] + col2[2]) / 2,
        ]

        console.log(combined);

        return combined;
    }

    /**
     * @param {*} inpColor hex or rgb array
     * @param {*} otherColors array of hex or rgb arrays
     * @returns {Array} closest color in RGB array
     */
    closestColor(inpColor, otherColors) {

        // if (typeof inpColor === 'string')
        inpColor = this.smartCastToRGBArray(inpColor);

        let temp = otherColors;
        otherColors = [];

        for (let color of temp)
            // if (typeof color === 'string')
            // color = this.smartCastToRGBArray(color);
            otherColors.push(this.smartCastToRGBArray(color));

        let closest = otherColors[0];
        let minDistance = this.distance(inpColor, closest);

        // console.log(otherColors);

        for (const color of otherColors) {

            const distance = this.distance(inpColor, color);

            if (distance < minDistance) {
                minDistance = distance;
                closest = color;
            }
        }

        return closest;
    }

    /**
     * Calculates the Euclidean distance between three points in 3D space.
     * @param {Array} point1 - [x1, y1, z1]
     * @param {Array} point2 - [x2, y2, z2]
     * @returns {number} - Distance between point1 and point2
    */
    distance(point1, point2) {
        return Math.sqrt(
            Math.pow(point2[0] - point1[0], 2) +
            Math.pow(point2[1] - point1[1], 2) +
            Math.pow(point2[2] - point1[2], 2)
        );
    }
}