export default class Random{

    constructor(){


    }

    /**
     * 
     * @param {int} min included 
     * @param {int} max excluded 
     * 
     * @returns {int}
     */
    rangeInt(min, max){

        return Math.floor(Math.random() * (max - min) + min - 0.0001);
    }
}