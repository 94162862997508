import CookiesHandler from "./CookiesHandler";
import Object3D from "./object3d";
import Paths from "./paths";
import PlayerModel from "./PlayerModel";

export default class SkinHandler {


    constructor(player) {

        /**
         * @type {PlayerModel}
         */
        this.player = player;
        /**
         * @type {CookiesHandler}
         */
        this.cookiesHandler = new CookiesHandler();

        this.uploadedSkinExists = false;
        this.loadedSkins = {
            "uploaded": ""
        };

        // this.fetchOnlineSkin();
        this.setupSkinSelect();

        const setSkinUrl = this.loadedSkins[this.uploadedSkinExists ? "uploaded" : "alex"];
        // this.player.updateMeshWithTexture(setSkinUrl, true);
        this.player.setSkin(setSkinUrl);
    }

    setupSkinSelect() {

        this.loadSkins();
        const dropdown = document.getElementById("skin-dropdown").children[0];

        dropdown.innerHTML = "";

        for (const skinKey of Object.keys(this.loadedSkins)) {

            const skinValue = this.loadedSkins[skinKey];

            if (skinValue == "" || skinValue == undefined)
                continue;

            const option = document.createElement("option");
            option.value = skinValue;
            option.innerText = skinKey;

            dropdown.appendChild(option);
        }

        dropdown.addEventListener("change", (e) => {

            console.log("changed", e.target.value);
            // this.player.updateMeshWithTexture(e.target.value, true);
            this.player.setSkin(e.target.value);

        });
    }

    /**
     * 
     * @param {HTMLInputElement} skinInput 
     * @returns {SkinHandler}
     */
    addSkinInputListener(skinInput) {

        skinInput.addEventListener("change", this.uploadLocalSkin(skinInput));
        return this;
    }

    loadSkins() {

        const cookieSkin = this.loadCookieSkin(1);

        if (cookieSkin != undefined) {

            this.uploadedSkinExists = true;
            console.log("cookie skin", cookieSkin);


            this.loadedSkins = {
                ...{ "uploaded": cookieSkin },
                ...new Paths().getBaseSkins(),
            };
        }
        else {

            this.loadedSkins = {
                ...new Paths().getBaseSkins(),
            };
        }


        // this.player.setObjTextureWithTextureData("skins/alex.png");
    }

    loadCookieSkin(index) {

        const skin = this.cookiesHandler.getCookie("skin" + index);

        if (skin != "") {

            // setTimeout(() => {
            //     this.player.setObjTextureWithTextureData(skin.replace("&semicolon&", ";"));
            // }, 500);

            return skin.replace("&semicolon&", ";");
        }

        return undefined;
    }

    uploadLocalSkin(skinInput) {

        //Skin file must be small for it to work
        //Wont work with large files

        return (e) => {

            console.log("uploading skin cookie");

            // console.log(skinInput.value);
            const reader = new FileReader();
            reader.addEventListener("load", () => {

                // console.log("loaded", reader.result);

                console.log("result", reader.result, typeof reader.result);
                this.addSkinCookie(1, reader.result.replace(";", "&semicolon&"));
                // this.player.updateMeshWithTexture(reader.result, true);
                this.player.setSkin(reader.result);


                this.setupSkinSelect();

            });
            reader.readAsDataURL(skinInput.files[0]);
        };
    }

    addSkinCookie(index, texture) {

        this.cookiesHandler.overrideCookie("skin" + index, texture);
    }

    //Nope, not possible rn
    fetchOnlineSkin() {

        // https://wiki.vg/Mojang_API#UUID_-.3E_Profile_.2B_Skin.2FCape
        fetch("http://textures.minecraft.net/texture/b795171365dd8a23d4f4150b74022e0a9dfda903531b81175302795c98bb79c")
            .then(resp => {

                console.log(resp);
            });

        return new Promise(() => {

            fetch("https://api.mojang.com/users/profiles/minecraft/jikkinikki").then(resp => resp.json())
                .then(userData => {

                    console.log(userData);

                    fetch(`https://sessionserver.mojang.com/session/minecraft/profile/${data.id}`).then(resp => resp.json())
                        .then(skinData => {

                            console.log(skinData);

                            const encoded = skinData.properties[0].value;

                            const decocded = atob(encoded);

                            const skinUrl = decocded.textures.SKIN.url;

                            console.log(skinUrl);
                        })
                });
        });
    }
}