import * as THREE from "three";
// import THREE from "./three_import.js";
import Camera from "./camera.js";
import HtmlTrim from "./html_trim.js";
import Paths from "./paths.js";
import modTools from "./mods.js";
import SceneHandler from "./scene_handler.js";
import ArmorPiecesHandler from "./armor_pieces_handler.js";
import Object3D from "./object3d.js";
import TrimSetsHandler from "./trim_sets_handler.js";
import SkinHandler from "./SkinHandler.js";
import PopupsHandler from "./PopupsHandler.js";
import AdvancedMenu from "./AdvancedMenu.js";
import Clouds from "./Clouds.js";
import ExtraTool from "./ExtraTool.js";
import cookies_config from "./cookies_config.js";
import PlayerModel from "./PlayerModel.js";

THREE.Cache.enabled = true;

let trimSetsHandler = "";

const trimDisp = document.getElementById("trim-display");

const advancedMenu = new AdvancedMenu(document.getElementById("extra-menu"));

const popupsHandler = new PopupsHandler();
// const modTools = new ModTools();
const modTools2 = modTools

const sceneHandler = new SceneHandler();
sceneHandler.newSceneSet(trimDisp);

const extraTool = new ExtraTool();
const armorPiecesHandler = new ArmorPiecesHandler(sceneHandler.mainScene, extraTool);

// const playerURL = new URL("../objects/player2.obj", import.meta.url);
// const player = new Object3D().objFromURL(playerURL);

const player = new PlayerModel(sceneHandler).setModel(new URL("../objects/player.obj", import.meta.url), new URL("../objects/player_layers.obj", import.meta.url));

const skinHandler = new SkinHandler(player).addSkinInputListener(document.getElementById("skin-input"));

// sceneHandler.mainScene.addObj(player);

const htmlTrim = new HtmlTrim(armorPiecesHandler, advancedMenu);

htmlTrim.createHtmlSingleEdits(document.getElementById("edit-single"), armorPiecesHandler);
htmlTrim.createHtmlMultiEdit(document.getElementById("edit-all"), armorPiecesHandler);

// ----------------------------------------------------------------
//CUSTOM OPTIONS - Needs to be added inside somewhere else as well!

htmlTrim.addCustomArmorOption("helmet", "turtle", "items/armor/turtle_helmet.png");
htmlTrim.addCustomArmorOption("chestplate", "elytra", "items/armor/elytra.png");


// ----------------------------------------------------------------

trimSetsHandler = new TrimSetsHandler(armorPiecesHandler);

for (const pieceKey of Object.keys(armorPiecesHandler.pieces)) {

    armorPiecesHandler.pieces[pieceKey].updateDivs();
    // console.log(armorPiecesHandler.pieces[pieceKey]);
}

// sceneHandler.mainScene.updateRendererToWithHTMLElement(trimDisp)
window.addEventListener("resize", () => {

    sceneHandler.mainScene.updateRendererToWithHTMLElement(trimDisp)
});

// -----------------------------------------

// advancedMenu.open([armorPiecesHandler.pieces["helmet"]]);

document.addEventListener("keydown", e => {

    if (e.key == "x") {

        advancedMenu.close();
    }
});

new Clouds(document.getElementById("graphics"));

// for (let i = 0; i < 10; i++) {

//     let val = 16 ** i;
//     val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//     console.log(i + ":", val);

// }

let btnTextId = 0;

document.getElementById("screenshot").addEventListener("click", e => {

    console.log(document.getElementsByTagName("canvas")[0]);

    const dataURL = document.getElementsByTagName("canvas")[1].toDataURL("image/png");

    const a = document.createElement('a');
    a.href = dataURL
    a.download = `${"armor_trim"}.jpeg`;
    a.click();

    setupTextInterval("Downloaded screenshot!", ++btnTextId);

});

document.getElementById("join-discord").addEventListener("click", e => {

    window.open("https://discord.gg/PBtRnr3Hn4", "_blank");
});

document.getElementById("share").addEventListener("click", e => {

    //get current url
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl + "/?" + extraTool.urlHandler.genFullUrlFromObjs());

    setupTextInterval("Copied to clipboard!", ++btnTextId);

});

function setupTextInterval(text, id) {

    console.log("asd");

    const textBtn = document.getElementById("btn-click-text");

    textBtn.innerText = text;
    textBtn.classList.add("btn-text-clicked");

    const duration = 1500;
    const cssDuration = parseFloat(window.getComputedStyle(textBtn).transitionDuration);

    setTimeout(() => {

        if (btnTextId == id)
            textBtn.classList.remove("btn-text-clicked");

    }, duration - cssDuration * 1000);

    setTimeout(() => {

        if (btnTextId == id)
            textBtn.innerText = "";

    }, duration);
};

function setActiveWindow(clickedElem) {

    for (const elem of modelHandler.getElementsByTagName("nav")[0].children) {

        if (clickedElem != elem) {

            elem.classList.remove("selected");

        } else {

            elem.classList.add("selected");
        }
    }

    for (const window of modelHandler.children[1].children) {

        if (clickedElem.getAttribute("window") == window.id) {

            window.classList.remove("hidden");
        }
        else
            window.classList.add("hidden");

    }
}
const modelHandler = document.getElementById("model-handling");

setActiveWindow(modelHandler.getElementsByTagName("nav")[0].children[0]);
for (const elem of modelHandler.getElementsByTagName("nav")[0].children) {

    elem.addEventListener("click", () => {
        setActiveWindow(elem);
    });
}



// -------------------------------------------------------------------------

//Phone layout handling

function setOpenWindow(id) {

    const parts = document.getElementById("trim-editor").children;

    for (const part of parts) {

        // if (part != parts[0])
        part.classList.add("hidden");
    }
    console.log(id);

    document.getElementById(id).classList.remove("hidden");
}

if (window.innerWidth < 630) {

    const parts = document.getElementById("trim-editor").children;

    for (const part of parts) {

        if (part != parts[0])
            part.classList.add("hidden");
    }
}

const phoneNav = document.getElementById("phone-nav");

if (window.innerWidth > 630)
    phoneNav.classList.add("hidden");

for (const btn of phoneNav.children[0].children) {

    // console.log(btn);

    btn.addEventListener("click", () => {

        for (const btn2 of phoneNav.children[0].children)
            btn2.classList.remove("selected");

        btn.classList.add("selected");
        setOpenWindow(btn.getAttribute("window"));
    });

}

document.getElementById("toggle-trim-sets").addEventListener("click", () => {

    document.getElementById("trim-sets").classList.toggle("hide-trim-sets");
});