import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import Object3D from './object3d';
import CameraMover from './CameraMover';

export default class Scene {

    constructor(canvasHolder) {

        const scene = new THREE.Scene();

        const renderer = new THREE.WebGLRenderer({
            preserveDrawingBuffer: true,
            antialias: true,
            // outputEncoding: THREE.sRGBEncoding

        });

        renderer.setSize( canvasHolder.offsetWidth, canvasHolder.offsetHeight * 1);

        const reOb = new ResizeObserver(() => {

            console.log("asdadsasdasd");
            this.updateRendererToWithHTMLElement(canvasHolder)
            renderer.setSize(canvasHolder.offsetWidth, canvasHolder.offsetHeight * 1);
        });

        reOb.observe(canvasHolder);
        canvasHolder.appendChild(renderer.domElement);

        const camera = this.newCamera(canvasHolder);

        this.sceneContent = {

            /** @type {THREE.PerspectiveCamera} */
            camera: camera,
            renderer: renderer,
            scene: scene,
            objects: []
        }

        const orbit = new OrbitControls(camera, renderer.domElement);
        // camera.position.set(0, 2, 5)
        // orbit.autoRotate = true;
        // orbit.enableRotate = true;
        // orbit.update();
        // camera.position.y = 0.5;
        //const axesHelper = new THREE.AxesHelper(5);
        //scene.add(axesHelper);

        this.render(this.sceneContent);
        this.addLight();

        const backColorAtStart = "#71BAFA";

        this.setBackgroundColor(backColorAtStart);
        this.cameraMover = new CameraMover(camera, "still", 0.0007, 5);

        renderer.domElement.addEventListener("mousedown", () => {

            if (this.cameraMover._moveType_ != "still") {

                // this.cameraMover.setMovementType("still");
                this.cameraMover.stillBtn.click();
            }
        }
        );

        document.getElementById("background-color").addEventListener("input", (e) => {

            this.setBackgroundColor(e.target.value);
        });

        document.getElementById("background-color").value = backColorAtStart;
    }

    setRotationMode(mode) {

        this.cameraMover.setMovementType(mode);
    }

    updateRendererToWithHTMLElement(htmlElement) {

        this.sceneContent.camera.aspect = htmlElement.offsetWidth / (htmlElement.offsetHeight * 1);
        this.sceneContent.camera.updateProjectionMatrix();

        // canvasHolder.offsetWidth / (canvasHolder.offsetHeight * 0.77), 0.1, 1000
    }

    addLight() {

        // const light = new THREE.AmbientLight("red"); // soft white light
        // const light = new THREE.AmbientLight("0x404040", 3); // soft white light
        const light = new THREE.AmbientLight("0x404040", 3); // soft white light
        // const light = new THREE.AmbientLight("rgb(250, 250, 220)", 3); // soft white light
        // light.intensity = 2.5;
        this.sceneContent.scene.add(light);
    }

    removeObj(obj) {

        const scene = this.sceneContent.scene;

        if (typeof obj.model.then == "function") {

            obj.model.then(model => {

                this.sceneContent.objects.splice(this.sceneContent.objects.indexOf(obj), 1);
                scene.remove(model);
            });
        }
        else {

            this.sceneContent.objects.splice(this.sceneContent.objects.indexOf(obj), 1);
            scene.remove(obj.model);
        }
    }

    /**
     * 
     * @param {Object3D} obj 
     */
    addObj(obj) {

        const scene = this.sceneContent.scene;

        obj.setScene(this);

        if (typeof obj.model.then == "function") {

            obj.model.then(model => {
                // console.log(obj);
                this.sceneContent.objects.push(obj);
                scene.add(model);
            });
        }
        else {

            this.sceneContent.objects.push(obj);
            // console.log(obj);
            scene.add(obj.model);
        }
    }

    /**
     * Renders the scene.
     * @param {{camera: Camera, renderer: Renderer, scene: Scene, objects: Object3D[]}} scene_dict
     */
    render(scene_dict) {

        // cameraMover.update();
        requestAnimationFrame(() => { this.render(scene_dict) });
        scene_dict.renderer.render(scene_dict.scene, scene_dict.camera);

        for (const obj of scene_dict.objects) {

            if (obj.textureAnimation) {

                const data = obj.textureAnimation.tickAnim();

                if (data != undefined)
                    obj.setObjTextureWithTextureData(data, true);
            }
        }

        // renderer.setClearColor(backColorPicker.value)
    }

    newCamera(canvasHolder) {

        const camera = new THREE.PerspectiveCamera(70, canvasHolder.offsetWidth / (canvasHolder.offsetHeight * 0.77), 0.1, 1000);
        camera.position.z = 5;
        camera.updateProjectionMatrix();
        return camera;
    }

    setBackgroundColor(color) {

        this.sceneContent.renderer.setClearColor(color);
    }

}