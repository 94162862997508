export default class HtmlGen {

    /**
     * 
     * @param {string} type - html tag
     * @param {string} text - text to put in tag
     * @param {HTMLElement} parent - html element
     * @param {string} id 
     * @param {string[]} classes 
     * @returns {HTMLElement} HTMLElement
     */
    createElem(type, text, parent, id, classes) {

        const newElem = document.createElement(type);

        if (text)
            newElem.innerText = text;

        if (id)
            newElem.id = id;

        if (classes)
            classes.forEach(className => newElem.classList.add(className));

        if (parent)
            parent.appendChild(newElem);
        else
            console.trace("missing parent");

        return newElem;
    }

    dropdownEvent(e) {

        const closedElem = this.closeDropdowns();

        if (closedElem != e)
            e.classList.remove("hidden");
    }

    closeDropdowns() {

        const dropdowns = document.getElementsByClassName("dropdown");

        let openOne = "";

        for (let int = 0; int < dropdowns.length; int++) {
            const dropdown = dropdowns[int];

            if (!dropdown.classList.contains("hidden")) {

                if (openOne != "")
                    console.log("error, multiple dropdowns open at once! Should never happen.");

                openOne = dropdown;
                dropdown.classList.add("hidden");
            }
        }
        return openOne;
    }
}