import CookiesHandler from "./CookiesHandler.js";

class CookiesConfig {

    constructor() {

        this.cookieId = "20240904";

        this.cookiesHandler = new CookiesHandler();

        this.cookieBanner = document.getElementById("cookie-banner");
        this.detailedCookies = document.getElementById("detailed-cookies");
        this.acceptSelectedElem = document.getElementById("accept-selected");

        this.addFuncToAllClasses("consent-all-cookies", () => {

            this.acceptAllCookies();
        });

        this.addFuncToAllClasses("customise-cookies", () => {

            this.seeMoreOptions();
        });

        this.addFuncToAllClasses("conesent-selected", () => {

            this.seeMoreOptions();
        });

        this.acceptSelectedElem.addEventListener("click", () => {

            this.acceptSelected();
        });

        document.getElementById("update-cookies-btn").addEventListener("click", () => {

            this.detailedCookies.classList.remove("hidden");
        });

        console.log(this.getAnalytcisCookie());
        console.log(this.cookiesHandler.getCookie("allowTargetedAds"));

        if (this.getAnalytcisCookie() == "" || this.cookiesHandler.getCookie("allowTargetedAds") == "") {

            setTimeout(() => {

                this.cookieBanner.classList.remove("hidden");

            }, 3000);
        }
        else {

            console.log(this.cookiesHandler.getCookie("allowTargetedAds"));

            this.updateGA4Consent(this.getAnalytcisCookie());
            // dataLayer.push({ 'Google Analytics': `${this.getAnalytcisCookie()}` });
            dataLayer.push({ 'targeted ads': `${this.cookiesHandler.getCookie("allowTargetedAds")}` });

            document.getElementById("googleAnalyticsToggle").checked = this.stringToBool(this.getAnalytcisCookie());
            document.getElementById("targetedAdsToggle").checked = this.stringToBool(this.cookiesHandler.getCookie("allowTargetedAds"));
        }

        // this.cookiesHandler.overrideCookie("allowGoogleAnalytics", "")
        // this.setAnalytcisCookie("");
        // this.cookiesHandler.overrideCookie("allowTargetedAds", "")
    }

    getAnalytcisCookie(){

        return this.cookiesHandler.getCookie("allowGoogleAnalytics" + this.cookieId);
    }

    setAnalytcisCookie(value){

        this.cookiesHandler.overrideCookie("allowGoogleAnalytics" + this.cookieId, value);

    }

    seeMoreOptions() {

        this.cookieBanner.classList.add("hidden");
        this.detailedCookies.classList.remove("hidden");
    }

    acceptAllCookies() {

        this.cookieBanner.classList.add("hidden");
        this.detailedCookies.classList.add("hidden");

        // this.cookiesHandler.overrideCookie("allowGoogleAnalytics", true);
        this.setAnalytcisCookie(true);
        this.cookiesHandler.overrideCookie("allowTargetedAds", true);

        document.getElementById("googleAnalyticsToggle").checked = true;
        document.getElementById("targetedAdsToggle").checked = true;

        this.updateGA4Consent(true);
        this.updateTargetedAdsConsent(true);
        // dataLayer.push({ 'Google Analytics': 'true' });
        // dataLayer.push({ 'targeted ads': 'true' });
    }

    updateGA4Consent(consented) {

        return dataLayer.push({ 'Google Analytics': `'${consented}'`, "event": "consent_status.*" });
    }

    updateTargetedAdsConsent(consented) {

        dataLayer.push({ 'targeted ads': consented });
        // return dataLayer.push({ 'Google Analytics': `'${consented}'`, "event": "consent_status.*" });
    }

    acceptSelected() {

        this.cookieBanner.classList.add("hidden");
        this.detailedCookies.classList.add("hidden");

        // this.cookiesHandler.overrideCookie("allowGoogleAnalytics", document.getElementById("googleAnalyticsToggle").checked);
        this.setAnalytcisCookie(document.getElementById("googleAnalyticsToggle").checked);
        this.cookiesHandler.overrideCookie("allowTargetedAds", document.getElementById("targetedAdsToggle").checked);

        this.updateGA4Consent(document.getElementById("googleAnalyticsToggle").checked);
        this.updateTargetedAdsConsent(document.getElementById("targetedAdsToggle").checked);

        // dataLayer.push({ 'Google Analytics': `${document.getElementById("googleAnalyticsToggle").checked}` });
        // dataLayer.push({ 'targeted ads': `${document.getElementById("targetedAdsToggle").checked}` });
    }

    addFuncToAllClasses(className, func) {

        const elements = document.getElementsByClassName(className);

        for (const elem of elements) {
            elem.addEventListener("click", func);
        }
    }

    stringToBool(str) {

        return str == "true";
    }
}

export default new CookiesConfig();