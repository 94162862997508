import * as THREE from 'three';

export default class Clouds {

    constructor(canvas) {

        this.boxes = [];
        this.zScale = 0.7;
        this.ySt = 9.5;
        this.zSt = -10;
        this.timeSpeed = 0.004;
        this.xRange = 30;

        this.canvas = canvas;

        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        canvas.appendChild(this.renderer.domElement);

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            10000
        );

        this.onStart();

        // this.renderer.setClearColor("red");

        const ambientLight = new THREE.AmbientLight("white", 2);

        const directionalLight = new THREE.DirectionalLight("white", 10);

        directionalLight.position.set(-30, 50, 10)

        const dirHelper = new THREE.DirectionalLightHelper(directionalLight);

        this.scene.add(ambientLight);

        this.scene.add(directionalLight);
        // this.scene.add(dirHelper)

        this.camera.position.z = 5;

        this.renderer.render(this.scene, this.camera);

        // this.animate();
        this.timeLeft = 1;

        const update = () => {

            for (let i = this.boxes.length - 1; i >= 0 ; i--) {
                const box = this.boxes[i];
                
                box.position.x += this.timeSpeed;

                if (box.position.x > 50)
                    this.removeItem(box);
            }

            this.timeLeft -= this.timeSpeed;

            if (this.timeLeft <= 0) {

                this.timeLeft = 1;

                if (!(Math.random() < 0.7)) {

                    this.spawnCloud(-this.xRange, this.ySt, this.zSt + Math.random() % 3);
                }
            }

            this.renderer.render(this.scene, this.camera);
        }

        this.renderer.setAnimationLoop(update);
    }

    onStart() {

        for (let x = -this.xRange; x <= this.xRange; x++) {

            if (Math.random() < 0.7)
                continue;

            this.spawnCloud(x, this.ySt, this.zSt + Math.random() % 3);
        }
    }

    spawnCloud(x, y, z) {

        this.addItem(this.createBox(x, y, z));

        for (let x1 = -1; x1 <= 1; x1++) {

            for (let z1 = -this.zScale; z1 <= 1 * this.zScale; z1 += this.zScale) {

                if (Math.random() < 0.6)
                    continue;

                this.addItem(this.createBox(x + x1, y, z + z1));

            }
        }
    }

    randomCloudShape() {

        const shapes = [

            [
                [0, 0, 0],
                [],
                [],
            ]
        ]
    }

    createCloud() {


    }

    addItem(b) {

        this.boxes.push(b);
        this.scene.add(b);
    }

    removeItem(item) {
        this.boxes.splice(this.boxes.indexOf(item), 1);
        this.scene.remove(item);
    }

    createBox(x, y, z) {

        const geometry = new THREE.BoxGeometry();
        const material = new THREE.MeshStandardMaterial({ color: "white" });

        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(x, y, z);
        cube.scale.set(1, 0.4, this.zScale);


        return cube;
    }


}
