import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import * as THREE from "three";
import TextureAmim from './texture_anim';

export default class Object3D {

    constructor() {

        this.model = "";
        this.animate = false;
        this.textureUrl = "";
    }

    animate_top_texure(texture) {

        this.textureAnimation = new TextureAmim().setup(this.textureUrl, texture);
    }

    // pathToURL(path){

    //     return new URL(path, import.meta.url)
    // }

    objFromURL(url) {

        const objLoader = new OBJLoader();

        this.model = new Promise((resolve, reject) => {

            objLoader.load(url.href, (obj) => {

                obj.position.y += 1.5;
                resolve(obj);
            });
        })

        this.model.then(obj => {

            this.model = obj;
            // console.log(obj.children);
        });

        return this;
    }

    loadTexture(url) {

        this.textureUrl = url;

        return new Promise((resolve, reject) => {

            const textureLoader = new THREE.TextureLoader();

            textureLoader.load(url, texture => {

                // console.log("wampa dam");

                texture.minFilter = THREE.NearestFilter;
                texture.magFilter = THREE.NearestFilter;
                texture.colorSpace = THREE.SRGBColorSpace;

                resolve(texture);
            }, () => { }, (error) => {
                console.log("inner error", error);
                reject(error);
            });

        }).catch(error => {
            console.log('A promise was rejected:', error);
            console.log(url);
        });
    }

    setScene(scene) {

        this.scene = scene;
        return this;
    }

    updateMeshWithTexture(textureSrc, applyTexture) {

        const promises = [
            typeof this.model.then == "function" ? this.model : new Promise(() => model),
            this.loadTexture(textureSrc)
        ];

        Promise.all(promises).then(result => {

            const group = result[0];
            const texture = result[1];

            if (applyTexture) {

                this.textureUrl = textureSrc;

                texture.minFilter = THREE.NearestFilter;
                texture.magFilter = THREE.NearestFilter;
                texture.colorSpace = THREE.SRGBColorSpace;

                for (const childMesh of group.children) {

                    childMesh.material = new THREE.MeshPhongMaterial({
                        map: texture,
                        // transparent: true,
                        side: THREE.DoubleSide
                    });
                }
            }

            const img = new Image();

            img.addEventListener("load", () => {

                const modelEditCanvas = document.createElement("canvas");

                modelEditCanvas.width = img.width;
                modelEditCanvas.height = img.height;
                const ctx = modelEditCanvas.getContext('2d', { willReadFrequently: true });
                ctx.drawImage(img, 0, 0);

                for (const object of group.children) {

                    for (let i = 0; i < object.geometry.attributes.position.count; i++) {

                        const uvX = object.geometry.attributes.uv.getX(i);
                        const uvY = object.geometry.attributes.uv.getY(i);
                        const pixelData = ctx.getImageData(uvX * img.width, img.height - uvY * img.height, 1, 1).data;

                        // console.log(object.geometry.attributes.position);
                        if (pixelData[3] == 0) {

                            object.geometry.attributes.position.setY(i, 300);
                            object.geometry.attributes.position.setX(i, 300);
                            object.geometry.attributes.position.setZ(i, 300);

                            // if(typeof object.geometry.attributes.position.array.splice == "function")
                            // object.geometry.attributes.position.array.splice(i, 1);

                            // let arr = Array.from(object.geometry.attributes.position.array);
                            // arr.splice(i, 1);
                            // object.geometry.attributes.position.array = new Float32Array(arr);
                        }
                    }
                    object.geometry.attributes.position.needsUpdate = true;
                }

            })
            img.src = textureSrc;

        }).catch((error) => {
            console.error('A promise was rejected:', error);
        });

        return this;
    }

    setObjTextureWithTextureData(textureData, freeOld) {

        if (freeOld) {


        }

        const model = this.model;
        this.TextureData = textureData;

        new THREE.TextureLoader().load(textureData,
            texture => {

                texture.minFilter = THREE.NearestFilter;
                texture.magFilter = THREE.NearestFilter;
                texture.colorSpace = THREE.SRGBColorSpace;

                new Object3D().setObjTexture(texture, model);

                if (typeof model.then == "function")
                    model.then(loaded => {

                        for (const box of loaded.children) {
                            box.material = new THREE.MeshPhongMaterial({
                                map: texture,
                                // transparent: true,
                                side: THREE.DoubleSide
                            });
                        }
                    });
                else {

                    for (const box of model.children) {
                        box.material = new THREE.MeshPhongMaterial({
                            map: texture,
                            // transparent: true,
                            side: THREE.DoubleSide
                        });
                    }
                }


            },
            () => { },
            (error) => {
                console.error('An error happened:', error);
            }
        );
    }

    setObjTexture(textureSrc, obj) {

        if (obj) {

            this.model = obj;
        }
        this.model.textureUrl = textureSrc;

        const promises = [
            typeof this.model.then == "function" ? this.model : new Promise((resolve) => resolve(this.model)),
            this.loadTexture(textureSrc)
        ];

        const p = Promise.all(promises).then(result => {

            const group = result[0];
            const texture = result[1];

            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;
            texture.colorSpace = THREE.SRGBColorSpace;
            
            for (const childMesh of group.children) {

                childMesh.material = new THREE.MeshPhongMaterial({
                    map: texture,
                    // transparent: true,
                    side: THREE.DoubleSide
                });
            }

        }).catch((error) => {
            console.error('A promise was rejected:', error);
            console.trace('A promise was rejected:', error);
        });

        //print promise all progress after 3 seconds
        setTimeout(() => {

            // console.log(p);

        }, 1000);

        return this;
    }

    setObjTextureWithURLData(urlData, obj) {

        if (obj) {

            this.model = obj;
        }

        const promises = [
            typeof this.model.then == "function" ? this.model : new Promise(() => this.model),
            typeof urlData.then == "function" ? urlData : new Promise(() => urlData),

        ];

        Promise.all(promises).then(result => {

            const group = result[0];
            const dataURL = result[1];

            let texture = textureLoader.load(dataURL,

                () => {
                    texture.minFilter = THREE.NearestFilter;
                    texture.magFilter = THREE.NearestFilter;
                    texture.colorSpace = THREE.SRGBColorSpace;

                    for (const childMesh of group.children) {

                        childMesh.material = new THREE.MeshPhongMaterial({
                            map: texture,
                            // transparent: true,
                            side: THREE.DoubleSide
                        });
                    }
                }
            );

        }).catch((error) => {
            console.error('A promise was rejected:', error);
            console.trace('A promise was rejected:', error);
        });

        return this;
    }
}