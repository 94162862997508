import HtmlGen from "./html_gen";
import Paths from "./paths";
import ArmorPiecesHandler from "./armor_pieces_handler";
import ArmorPiece from "./armor_piece";
import Random from "./Random.js";
import AdvancedMenu from "./AdvancedMenu.js";

export default class HtmlTrim {

    constructor(armorPiecesHandler, advancedMenu) {

        this.armors = {};
        this.htmlDropdowns = {
            "helmet": {},
            "chestplate": {},
            "leggings": {},
            "boots": {},
            "full": {}
        }
        this.htmlGen = new HtmlGen();

        /** @type {Paths} */
        this.paths = new Paths();

        /** @type {ArmorPiecesHandler}*/
        this.armorPiecesHandler = armorPiecesHandler;
        this.createhtmlDice(document.getElementById("dice"));

        /** @type {AdvancedMenu} */
        this.advancedMenu = advancedMenu;
    }
    /**
     * @param {HTMLElement} holderDiv 
     * @param {ArmorPiecesHandler} armorPiecesHandler 
     */
    createHtmlSingleEdits(holderDiv, armorPiecesHandler) {

        for (const piece of this.paths.pieceTypes) {

            const armorPiece = armorPiecesHandler.pieces[piece];

            /**
             * @param {string} armorMaterial 
             * @param {HTMLElement} htmlElem 
             * @returns 
             */
            const selectArmorFunc = (armorMaterial, htmlElem) => {

                if (htmlElem.parentElement.classList.contains("hidden"))
                    return;

                armorPiece.setArmorMaterial(armorMaterial);
            }

            /**
             * @param {string} armorMaterial 
             * @param {HTMLElement} htmlElem 
             * @returns 
             */
            const selectTrimFunc = (trimModel, htmlElem) => {

                if (htmlElem.parentElement.classList.contains("hidden"))
                    return;

                armorPiece.setTrimModel(trimModel);
            }

            /**
             * @param {string} armorMaterial 
             * @param {HTMLElement} htmlElem 
             * @returns 
             */
            const selectTrimPalette = (palette, htmlElem) => {

                if (htmlElem.parentElement.classList.contains("hidden"))
                    return;

                // console.log(palette);
                armorPiece.setTrimPalette(palette);
            }

            const setLeatherColor = (color, htmlElem) => {

                if (htmlElem.parentElement.classList.contains("hidden"))
                    return;

                // console.log(color);
                armorPiece.setLeatherColor(color);
            }

            this.createHtmlPieceRow(piece, holderDiv, [armorPiece], selectArmorFunc, selectTrimFunc, selectTrimPalette, setLeatherColor);
        }
    }

    addCustomArmorOption(pieceType, pieceName, iconPath) {

        const htmlGen = this.htmlGen;
        const dropdownDiv = this.htmlDropdowns[pieceType]["armors"];
        // console.log(dropdownDiv.parentElement);
        const dispImg = dropdownDiv.parentElement.children[0];

        const img = htmlGen.createElem('img', '', dropdownDiv);
        img.src = iconPath;
        img.alt = pieceName;

        if (!dispImg.src) {
            dispImg.src = img.src;
            dispImg.alt = img.alt;
        }

        img.addEventListener("click", () => {

            htmlGen.closeDropdowns();
        });

        img.addEventListener("mouseenter", () => {

            if (img.parentElement.classList.contains("hidden"))
                return;

            dispImg.src = img.src;
            dispImg.alt = img.alt;
        });

        img.addEventListener("mouseenter", () => { this.selectArmor(pieceType, pieceName, img) });
    }

    /**
    * @param {string} armorMaterial 
    * @param {HTMLElement} htmlElem 
    * @returns 
    */
    selectArmor(pieceType, armorMaterial, htmlElem) {

        if (htmlElem.parentElement.classList.contains("hidden"))
            return;

        this.armorPiecesHandler.pieces[pieceType].setArmorMaterial(armorMaterial);
    }

    /**
     * @param {HTMLElement} holderDiv 
     * @param {ArmorPiecesHandler} armorPiecesHandler 
     */
    createHtmlMultiEdit(holderDiv, armorPiecesHandler) {

        const piece = this.paths.pieceTypes[1];
        const armorPiece = armorPiecesHandler.pieces[this.paths.pieceTypes[1]];

        /**
         * @param {string} armorMaterial 
         * @param {HTMLElement} htmlElem 
         */
        const selectArmorFunc = (armorMaterial, htmlElem) => {

            if (htmlElem.parentElement.classList.contains("hidden"))
                return;

            for (const armorPieceKey of Object.keys(armorPiecesHandler.pieces)) {

                armorPiecesHandler.pieces[armorPieceKey].setArmorMaterial(armorMaterial);
                armorPiecesHandler.pieces[armorPieceKey].updateDivs();
            }
        }

        /**
         * @param {string} armorMaterial 
         * @param {HTMLElement} htmlElem 
         */
        const selectTrimFunc = (trimModel, htmlElem) => {

            if (htmlElem.parentElement.classList.contains("hidden"))
                return;

            for (const armorPieceKey of Object.keys(armorPiecesHandler.pieces)) {

                armorPiecesHandler.pieces[armorPieceKey].setTrimModel(trimModel);
                armorPiecesHandler.pieces[armorPieceKey].updateDivs();
            }
        }

        /**
         * @param {string} armorMaterial 
         * @param {HTMLElement} htmlElem 
         */
        const selectTrimPalette = (palette, htmlElem) => {

            if (htmlElem.parentElement.classList.contains("hidden"))
                return;

            // console.log(palette);
            for (const armorPieceKey of Object.keys(armorPiecesHandler.pieces)) {

                armorPiecesHandler.pieces[armorPieceKey].setTrimPalette(palette);
                armorPiecesHandler.pieces[armorPieceKey].updateDivs();

            }
        }

        const setLeatherColor = (color, htmlElem) => {

            if (htmlElem.parentElement.classList.contains("hidden"))
                return;

            // console.log(color);
            for (const armorPieceKey of Object.keys(armorPiecesHandler.pieces)) {

                armorPiecesHandler.pieces[armorPieceKey].setLeatherColor(color);
                armorPiecesHandler.pieces[armorPieceKey].updateDivs();
            }
        }

        this.createHtmlPieceRow("full", holderDiv, Object.values(armorPiecesHandler.pieces), selectArmorFunc, selectTrimFunc, selectTrimPalette, setLeatherColor);
    }

    /** Create row options for a piece
     * 
     * @param {string} pieceName 
     * @param {HTMLElement} holderDiv 
     * @param {Array<ArmorPiece>} armorPieces 
     * @param {Function} func1 
     * @param {Function} func2 
     * @param {Function} func3 
     */

    createHtmlPieceRow(pieceName, holderDiv, armorPieces, func1, func2, func3, func4) {


        const htmlGen = this.htmlGen;
        const htmlPiece = {}
        this.armors[pieceName] = htmlPiece;

        const rowDiv = htmlGen.createElem('div', '', holderDiv);

        const selectArmorFunc = func1;
        const selectTrimFunc = func2;
        const selectTrimPalette = func3;
        const selectLeatherColor = func4;

        let specialList = {};

        if (pieceName == "full") {

            for (const pieceMaterial of this.paths.pieceMaterials) {

                specialList[pieceMaterial] = {
                    "icon": `items/armor/${pieceMaterial}_full.png`,
                    "name": `${pieceMaterial}_full.png`
                }
            }
        }
        
        const armorPiece = armorPieces[0];

        armorPiece.htmlEdits.push(this.createHtmlPieceDropdownSlot(rowDiv, Object.keys(specialList).length == 0 ? this.paths.piecesPaths[pieceName] : specialList, selectArmorFunc, pieceName, "armors"));
        armorPiece.htmlEdits.push(this.createHtmlPieceDropdownSlot(rowDiv, this.paths.colors, selectLeatherColor, pieceName, "leatherColor"));
        armorPiece.htmlEdits.push(this.createHtmlPieceDropdownSlot(rowDiv, this.paths.trimModelsPaths, selectTrimFunc, pieceName, "trimModels"));
        armorPiece.htmlEdits.push(this.createHtmlPieceDropdownSlot(rowDiv, this.paths.trimPalettesPaths, selectTrimPalette, pieceName, "trimPalettes"));

        this.createHtmlTrimsMore(rowDiv, armorPieces);
    }

    /**
     * @param {HTMLDivElement} parentDiv 
     * @param {Array<ArmorPiece>} armorPieces 
     */
    createHtmlTrimsMore(parentDiv, armorPieces) {

        const holder = this.htmlGen.createElem("div", "", parentDiv, "", ["more", "burger-menu"]);

        this.htmlGen.createElem("div", "", holder, "", []);
        this.htmlGen.createElem("div", "", holder, "", []);
        this.htmlGen.createElem("div", "", holder, "", []);

        // console.log(armorPiece.pieceName);

        holder.addEventListener("click", () => {

            if (holder.classList.contains("burger-menu-open")) {

                this.advancedMenu.close();
                holder.classList.remove("burger-menu-open");
            }
            else {

                this.advancedMenu.open(armorPieces);
                holder.classList.add("burger-menu-open");
            }
        });

        this.advancedMenu.trackedMenuOpeners.push(holder);

    }

    /** create dropdown slot
     * 
     * @param {HTMLElement} holder 
     * @param {string{}} slotType
     * @returns HTMLElement slot
     */
    createHtmlPieceDropdownSlot(holder, content, clickFunc, pieceType, dropType) {

        const htmlGen = this.htmlGen;

        const slotDiv = htmlGen.createElem('div', '', holder, "", ["dropdown-box"]);
        const dispImg = htmlGen.createElem('img', '', slotDiv, "selected-disp");
        const dropdownDiv = htmlGen.createElem('div', '', slotDiv, '', ['dropdown', "hidden"]);
        dispImg.addEventListener("click", () => { htmlGen.dropdownEvent(dropdownDiv) });

        for (const key of Object.keys(content)) {

            const img = htmlGen.createElem('img', '', dropdownDiv);
            img.src = content[key]["icon"];
            img.alt = `${content[key]["name"]}`;

            if (!dispImg.src) {
                dispImg.src = img.src;
                dispImg.alt = img.alt;
            }

            img.addEventListener("click", () => {

                htmlGen.closeDropdowns();
            });

            img.addEventListener("mouseenter", () => {

                if (img.parentElement.classList.contains("hidden"))
                    return;

                dispImg.src = img.src;
                dispImg.alt = img.alt;
            });

            img.addEventListener("mouseenter", () => { clickFunc(key, img) });
            // img.addEventListener("click", () => { clickFunc(key) });
        }
        this.htmlDropdowns[pieceType][dropType] = dropdownDiv;
        return slotDiv;
    }

    /**
     * 
     * @param {HTMLDivElement} holderDiv 
     */
    createhtmlDice(holderDiv) {

        this.createDie(holderDiv, () => {

            for (const armorPieceKey of Object.keys(this.armorPiecesHandler.pieces)) {

                const armorPiece = this.armorPiecesHandler.pieces[armorPieceKey];

                const rand = new Random().rangeInt(0, this.paths.pieceMaterials.length);
                armorPiece.setArmorMaterial(this.paths.pieceMaterials[rand], true);
                armorPiece.updateDivs();
                armorPiece.updateTexture();
            }
        });

        this.createDie(holderDiv, () => {

            for (const armorPieceKey of Object.keys(this.armorPiecesHandler.pieces)) {

                const armorPiece = this.armorPiecesHandler.pieces[armorPieceKey];

                const rand = new Random().rangeInt(0, this.paths.leatherColors.length);
                armorPiece.setLeatherColor(this.paths.leatherColors[rand], true);
                armorPiece.updateDivs();
                armorPiece.updateTexture();
            }
        });

        this.createDie(holderDiv, () => {

            for (const armorPieceKey of Object.keys(this.armorPiecesHandler.pieces)) {

                const armorPiece = this.armorPiecesHandler.pieces[armorPieceKey];

                const rand = new Random().rangeInt(0, this.paths.trimModels.length);
                armorPiece.setTrimModel(this.paths.trimModels[rand], true);
                armorPiece.updateDivs();
                armorPiece.updateTexture();
            }
        });

        this.createDie(holderDiv, () => {

            for (const armorPieceKey of Object.keys(this.armorPiecesHandler.pieces)) {

                const armorPiece = this.armorPiecesHandler.pieces[armorPieceKey];

                const rand = new Random().rangeInt(0, this.paths.trimPalettes.length);
                armorPiece.setTrimPalette(this.paths.trimPalettes[rand], true);
                armorPiece.updateDivs();
                armorPiece.updateTexture();
            }
        });
    }

    /**
     * 
     * @param {HTMLDivElement} holderDiv 
     * @param {Function} func 
     */
    createDie(holderDiv, func) {

        const randomBtn = this.htmlGen.createElem("div", "", holderDiv, "", ["die"]);

        for (let i = 0; i < 9; i++) {
            this.htmlGen.createElem("div", "", randomBtn, "", ["die-blob"]);
        }

        this.setDieBtnDispNumb(randomBtn, Math.floor(Math.random() * 5.9));

        randomBtn.addEventListener("click", func);

        randomBtn.addEventListener("click", () => {

            this.setDieBtnDispNumb(randomBtn, Math.floor(Math.random() * 5.9));
        });
    }

    setDieBtnDispNumb(btn, number) {

        if (btn.children.length == 0)
            return;

        for (const child of btn.children) {

            child.classList.remove("die-blob");
        }

        for (const blobIndex of new Paths().getDiceLayout(number)) {

            btn.children[blobIndex].classList.add("die-blob");
        }
    }
}