export default class CookiesHandler {

    /** Does not override cookies!
     * 
     * @param {string} name 
     * @param {any} val 
     */
    tryAddCookie(name, val) {

        if (this.getCookie(name) == "")
            document.cookie = name + "=" + val + "; expires=Thu, 18 Dec 3080 12:00:00 UTC";
    }

    /**
     * @param {string} name 
     * @param {any} val 
     */
    overrideCookie(name, val) {
        document.cookie = name + "=" + val + "; expires=Thu, 18 Dec 3080 12:00:00 UTC";
    }

    /**
     * @param {string} name 
     */
    getCookie(name) {
        name = name + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    }

    removeCookie(cname) {

        document.cookie = cname + "=" + "remove" + "; expires=Thu, 18 Dec 1999 12:00:00 UTC";
    }
}