import HtmlGen from "./html_gen"
import Paths from "./paths.js";

export default class TrimInfoLoader {

    constructor() {

        /** @type {HTMLDivElement} */
        this.currentsholder = document.getElementById("selected-trims");
        /** @type {HTMLDivElement} */
        this.allHolder = document.getElementById("all-trims");
        this.paths = new Paths();

        this.loadAll(this.allHolder);

        this.currentlySelected = {

            "helmet": "",
            "chestplate": "",
            "leggings": "",
            "boots": ""
        };

        this.currentlySelectedInputListeners = [];
        // this.updateSelected("helmet", "dune");
    }

    updateSelected(piece, trim) {


        if (trim == "empty")
            return;


        this.currentlySelected[piece] = trim;

        for (const listener of this.currentlySelectedInputListeners) {

            removeEventListener("input", listener);
        }

        this.currentsholder.innerHTML = "<h2>Selected trims</h2>";

        for (const piece of Object.keys(this.currentlySelected)) {

            if (this.currentlySelected[piece] != "" || true) {

                if (this.currentlySelected[piece] == "")
                    this.currentlySelected[piece] = "dune";

                for (const child of this.allHolder.children) {

                    if (child.getAttribute("name") == this.currentlySelected[piece]) {

                        const cardClone = child.cloneNode(true);
                        const inp = cardClone.getElementsByTagName("input")[0];

                        const trimInfo = this.paths.getTrimInfo(this.currentlySelected[piece]);

                        const inputEvent = () => {
                            cardClone.getElementsByClassName("chance-text")[0].innerText = `Chance to find: ${this.calcChance(trimInfo.chance_to_find, parseInt(inp.value))}%`
                        };

                        inp.addEventListener("input", inputEvent);
                        this.currentlySelectedInputListeners.push(inputEvent);

                        this.currentsholder.appendChild(cardClone);
                    }
                }
            }
        }

    }

    /** @param {HTMLDivElement} allHolder */
    loadAll(allHolder) {

        this.allHolder = allHolder;

        for (const model of this.paths.trimModels) {

            if (model != "empty")
                this.loadSingle(allHolder, model);
        }
    }
    /** @param {HTMLDivElement} holder */
    loadSingle(holder, trimName) {

        const trimData = this.paths.getTrimInfo(trimName);
        const path = "items/crafting"

        const htmlGen = new HtmlGen();
        const card = htmlGen.createElem("div", "", holder, "", ["trim-card"]);

        // card.href = `trim.html?trim=${trimName}`;

        card.setAttribute("name", trimName);

        const trimInfo = this.paths.getTrimInfo(trimName);

        if (trimInfo == undefined)
            return;

        // console.log(trimInfo, trimInfo == undefined); 

        card.innerHTML = `
        
        <div>
            <img src=${this.paths.getTrimPath(trimName).icon} class="trim-img" alt="Image of ${trimName} smithing template.">
            <h2>${trimName}</h2>
            <p  class="desc">${trimInfo.desc}</p>
            <p>Duplicaton: ${trimData.dupe_material}</p>
            <img src="${path}/${trimData.dupe_material.replace(" ", "_").toLowerCase()}.png">
            <br>
            <br>
            <p>${trimInfo.box_type.charAt(0).toUpperCase() + trimInfo.box_type.slice(1)} checked:</p>
            <input type="number" name="chance_input" id="" value="1", min="1">
            <br>
            <p class="chance-text">Chance to find: ${this.calcChance(trimInfo.chance_to_find, 1)}% </p>
        </div>

        <div>
            <h3>Duplicate</h3>
            <div class="craft">
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${this.paths.getTrimPath(trimName).icon}"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${path}/${trimData.dupe_material.replace(" ", "_").toLowerCase()}.png"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
                <div><img src="${path}/${"diamond"}.png"></div>
            </div>
        </div>
        `;

        const inp = card.getElementsByTagName("input")[0];

        inp.addEventListener("input", () => {
            console.log(inp.value);
            card.getElementsByClassName("chance-text")[0].innerText = `Chance to find: ${this.calcChance(trimInfo.chance_to_find, parseInt(inp.value))}%`
        });

        holder.appendChild(card);
    }

    calcChance(chance, checks) {

        const chanceToNotFind = 1 - chance;

        let chanceToFind = 1 - (chanceToNotFind ** checks);
        const decimals = 2;

        // chanceToFind = Math.floor(chanceToFind * 10 ** (decimals + 2)) / (10 ** decimals)
        chanceToFind = this.toPercent(chanceToFind, decimals)

        if (checks == 1)
            chanceToFind = this.toPercent(chance, 1);

        return chanceToFind;
    }

    toPercent(number, decimals) {

        return Math.floor(number * 10 ** (decimals + 2)) / (10 ** decimals)
    }
}