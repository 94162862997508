class ModTools{

    constructor(){

        this._modToolsEnabled = false;
    }

    enableModTools(){

        console.log("future function");
        
        this._modToolsEnabled = true;
        console.log("existing tool functions can be found in this dict.");
        console.log(modTools.constructor.prototype);
    }
}

export default new ModTools();